import {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {usePrevious} from 'react-use';
import CallIcon from '@mui/icons-material/Call';
import SettingsIcon from '@mui/icons-material/Settings';
import ShareIcon from '@mui/icons-material/Share';
import classNames from 'classnames';
import clsx from 'clsx';
// @ts-ignore
import {VC} from 'vidyoclient-nativewebrtc-sdk';

import {VIDEO_CUSTOM_BG_BASE64_SRC, VIDEO_FILTER_OPTION, VIDEO_IS_CUSTOM_BG_SELECTED} from '@/constants/constants';
import {VIDEO_PROVIDER_SETTINGS, videoProvider} from '@/services/videoService';
import {VideoFilterOptions} from '@/types/video';
import copyToClipboard from '@/utils/copyToClipboard';
import {generalNotify} from '@/utils/notifyMessages';

import {defaultBgImage} from './utils/defaultBgImage';
import {registerCamera} from './registerCamera';
import {registerMicrophone} from './registerMicrophone';
import {registerSpeaker} from './registerSpeaker';
import Settings from './Settings';
import {registerFilter, unregisterFilter} from './utils';

import './styles.css';
import styles from './videoCall.module.scss';

type VideoCallWidgetProps = {
    currentMeetingId: string;
    user: {
        firstName: string;
        id: number;
        lastName?: string;
    };
    onClose?: () => void;
    isForceLoadingState?: boolean;
    newWindowRef?: React.MutableRefObject<Window | null>;
    onDisconnect?: () => void;
};

export const VideoCallWidget = ({
    currentMeetingId: currentCallMeetingId,
    user,
    onClose,
    onDisconnect,
    isForceLoadingState = false,
}: VideoCallWidgetProps) => {
    const ref = useRef<HTMLIFrameElement>(null);
    const prevMeetingId = usePrevious(currentCallMeetingId);
    const [isLoading, setIsLoading] = useState(true);
    const [isConnected, setIsConnected] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [isCameraOn, setIsCameraOn] = useState(true);
    const [isMicrophoneOn, setIsMicrophoneOn] = useState(true);
    const [isSpeakerOn, setIsSpeakerOn] = useState(true);

    const handleClose = useCallback(() => {
        onDisconnect?.();
        onClose?.();
    }, [onClose, onDisconnect]);
    const handleDisconnect = useCallback(() => {
        onDisconnect?.();
    }, [onDisconnect]);

    useEffect(() => {
        const connectionStatus = document.getElementById('connectionStatus');

        if (isForceLoadingState) {
            connectionStatus?.classList.add('loading');
        }
    }, [isForceLoadingState]);

    useLayoutEffect(() => {
        const fn = async () => {
            console.info('currentCallMeetingId', currentCallMeetingId);

            if (!currentCallMeetingId) {
                return;
            }

            if (prevMeetingId === currentCallMeetingId) return;

            window['vidyoConnector'] = await VC.CreateVidyoConnector({
                viewId: 'renderer',
                viewStyle: 'VIDYO_CONNECTORVIEWSTYLE_Default',
                remoteParticipants: 8,
                // logFileFilter: 'debug@VidyoClient debug@VidyoSDP debug@VidyoResourceManager',
                logFileFilter: '',
                logFileName: '',
                userData: 0,
                constraints: {},
            });

            if (!window.vidyoConnector) {
                console.error('CreateVidyoConnector failed');
                return;
            }

            await window.vidyoConnector.ConnectToRoomAsGuest({
                host: `${window.VIDYO_TENANT_URL}`,
                roomKey: currentCallMeetingId,
                roomPin: '1',
                displayName: `${user?.firstName} pid${user?.id}`,
                onSuccess: () => {
                    console.info(`vidyoConnector.ConnectToRoomAsGuest : onSuccess callback received`);
                    setIsLoading(false);
                    setIsConnected(true);
                },
                onFailure: (reason: any) => {
                    console.error('vidyoConnector.Connect : onFailure callback received', reason);
                    handleDisconnect();
                    setIsLoading(false);
                    setIsConnected(false);
                },
                onDisconnected: (reason: any) => {
                    console.info('vidyoConnector.Connect : onDisconnected callback received', reason);
                    setIsLoading(false);
                    setIsConnected(false);
                    handleDisconnect();
                },
            });

            await registerCamera();
            await registerSpeaker();
            await registerMicrophone();

            const savedMicId = localStorage.getItem('VIDEO_DEFAULT_MIC_ID');
            const savedCamId = localStorage.getItem('VIDEO_DEFAULT_CAMERA_ID');
            const savedSpeakerId = localStorage.getItem('VIDEO_DEFAULT_SPEAKER_ID');

            if (savedMicId) {
                const savedMic = window?.['microphone']?.find((mic: any) => mic.id === savedMicId);
                if (savedMic) {
                    await window.vidyoConnector.SelectLocalMicrophone({
                        localMicrophone: savedMic,
                    });
                }
            }

            if (savedCamId) {
                console.info('defCamId', savedCamId);
                const savedCam = window?.['camera']?.find((cam: any) => cam.id === savedCamId);
                if (savedCam) {
                    await window.vidyoConnector.SelectLocalCamera({
                        localCamera: savedCam,
                    });
                }
            }

            if (savedSpeakerId) {
                const savedSpeaker = window?.['speaker']?.find((spk: any) => spk.id === savedSpeakerId);
                if (savedSpeaker) {
                    await window.vidyoConnector.SelectLocalSpeaker({
                        localSpeaker: savedSpeaker,
                    });
                }
            }

            const savedCustomBg = localStorage.getItem(VIDEO_CUSTOM_BG_BASE64_SRC);
            const savedFilterOption = localStorage.getItem(VIDEO_FILTER_OPTION) as VideoFilterOptions;
            const savedIsCustomBgSelected = localStorage.getItem(VIDEO_IS_CUSTOM_BG_SELECTED);

            if (savedFilterOption === 'none') {
                return;
            }

            unregisterFilter();

            if (savedFilterOption === 'blur') {
                setTimeout(() => {
                    registerFilter(savedFilterOption);
                }, 0);
                return;
            }

            if (savedFilterOption === 'bg_image') {
                setTimeout(() => {
                    registerFilter(
                        'bg_image',
                        savedIsCustomBgSelected && savedCustomBg ? savedCustomBg : defaultBgImage,
                    );
                }, 0);
                return;
            }
        };

        fn();
    }, [
        currentCallMeetingId,
        handleClose,
        prevMeetingId,
        user?.firstName,
        user?.id,
        user.lastName,
        onDisconnect,
        handleDisconnect,
    ]);

    const handleCameraPrivacy = useCallback(() => {
        if (isCameraOn) {
            window?.vidyoConnector.SetCameraPrivacy({privacy: true});
            setIsCameraOn(false);
        } else {
            window?.vidyoConnector.SetCameraPrivacy({privacy: false});
            setIsCameraOn(true);
        }
    }, [isCameraOn]);

    const handleMicrophonePrivacy = useCallback(() => {
        if (isMicrophoneOn) {
            window?.vidyoConnector.SetMicrophonePrivacy({privacy: true});
            setIsMicrophoneOn(false);
        } else {
            window?.vidyoConnector.SetMicrophonePrivacy({privacy: false});
            setIsMicrophoneOn(true);
        }
    }, [isMicrophoneOn]);

    const handleSpeakerPrivacy = useCallback(() => {
        if (isSpeakerOn) {
            window?.vidyoConnector.SetSpeakerPrivacy({privacy: true});
            setIsSpeakerOn(false);
        } else {
            window?.vidyoConnector.SetSpeakerPrivacy({privacy: false});
            setIsSpeakerOn(true);
        }
    }, [isSpeakerOn]);

    return (
        <div
            id="videoCallWidget"
            ref={ref}
            className={styles.root}
            style={{
                visibility: 'visible',
                display: 'block',
                top: 0,
                left: 0,
                overflow: 'hidden',
                zIndex: 1000,
                pointerEvents: 'auto',
                width: `100%`,
                height: `100%`,
                minHeight: `100%`,
                minWidth: `100%`,
                backgroundColor: 'black',
            }}
        >
            {showSettings && (
                <Settings
                    onClose={() => {
                        setShowSettings(false);
                    }}
                />
            )}
            <div id="vidyoConnector" className={styles.vidyoConnector}>
                {isLoading && (
                    <div id="connectionStatus" className="loading">
                        <div className="connecting">
                            <CallIcon
                                className={classNames(styles.loadingIcon)}
                                style={{color: 'white', fontSize: '80px'}}
                            />
                            <div style={{color: 'white'}}>Connecting...</div>
                        </div>
                    </div>
                )}
                <div id="renderer-container" className={clsx(styles.rendererContainer, 'renderer-container')}>
                    <div id="renderer" className={styles.renderer}></div>
                </div>
                <div className={styles.toolbar}>
                    <div id="toolbarCenter" className={styles.toolbarCenter}>
                        <button
                            id="joinLeaveButton"
                            title="Join Conference"
                            className={classNames(styles.toolbarButton, isConnected ? 'callEnd' : 'callStart')}
                            onClick={() => {
                                handleClose();
                            }}
                        ></button>
                        <button
                            id="cameraButton"
                            title="Camera Privacy"
                            className={classNames(styles.toolbarButton, isCameraOn ? 'cameraOn' : 'cameraOff')}
                            onClick={() => {
                                handleCameraPrivacy();
                            }}
                        ></button>
                        <button
                            id="microphoneButton"
                            title="Microphone Privacy"
                            className={classNames(
                                styles.toolbarButton,
                                isMicrophoneOn ? 'microphoneOn' : 'microphoneOff',
                            )}
                            onClick={() => {
                                handleMicrophonePrivacy();
                            }}
                        ></button>
                        <button
                            id="speakerButton"
                            title="Speaker Privacy"
                            className={classNames(styles.toolbarButton, isSpeakerOn ? 'speakerOn' : 'speakerOff')}
                            onClick={() => {
                                handleSpeakerPrivacy();
                            }}
                        ></button>
                        <button
                            id="optionsButton"
                            title="Copy Meeting Link"
                            className={classNames(styles.toolbarButton)}
                            onClick={() => {
                                copyToClipboard(
                                    videoProvider.getSharedLink({
                                        meetingId: currentCallMeetingId,
                                        portalUrl: VIDEO_PROVIDER_SETTINGS.VIDYO.portalUrl,
                                    }),
                                )
                                    .then(() => {
                                        generalNotify({
                                            title: 'Success',
                                            message: 'Meeting link copied to clipboard',
                                            status: 'success',
                                        });
                                    })
                                    .catch((err) => {
                                        console.error(err);
                                    });
                            }}
                        >
                            <ShareIcon
                                style={{
                                    color: 'white',
                                    cursor: 'pointer',
                                    fontSize: '30px',
                                }}
                            />
                        </button>
                        <button
                            className={classNames(styles.toolbarButton)}
                            onClick={() => {
                                setShowSettings(true);
                            }}
                        >
                            <SettingsIcon />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
