import {useEffect, useState} from 'react';

import {MHButton, MHSelect} from '@/components/base';
import {VIDEO_DEFAULT_CAMERA_ID, VIDEO_DEFAULT_MIC_ID, VIDEO_DEFAULT_SPEAKER_ID} from '@/constants/constants';

import styles from './videoCall.module.scss';

type SelectOption = {
    value: string;
    label: string;
};

export const SettingsDevices = () => {
    const [curMic, setCurMic] = useState<SelectOption | null>(null);
    const [curSpeaker, setCurSpeaker] = useState<SelectOption | null>(null);
    const [curCamera, setCurCamera] = useState<SelectOption | null>(null);
    const [cameraList, setCameraList] = useState<SelectOption[]>(
        window?.['camera']?.map((camera: any) => ({
            value: camera.id,
            label: camera.name,
        })) || [],
    );
    const [micList, setMicList] = useState<SelectOption[]>(
        window?.['microphone']?.map((mic: any) => ({
            value: mic.id,
            label: mic.name,
        })) || [],
    );
    const [speakerList, setSpeakerList] = useState<SelectOption[]>(
        window?.['speaker']?.map((speaker: any) => ({
            value: speaker.id,
            label: speaker.name,
        })) || [],
    );

    useEffect(() => {
        const loadDefaultDevices = () => {
            const defaultMic = localStorage.getItem(VIDEO_DEFAULT_MIC_ID);
            const defaultSpeaker = localStorage.getItem(VIDEO_DEFAULT_SPEAKER_ID);
            const defaultCamera = localStorage.getItem(VIDEO_DEFAULT_CAMERA_ID);

            const curMicObj = window?.['microphone']?.find((mic: any) => mic.id === defaultMic);
            const curSpeakerObj = window?.['speaker']?.find((spk: any) => spk.id === defaultSpeaker);
            const curCameraObj = window?.['camera']?.find((camera: any) => camera.id === defaultCamera);

            setCurMic({
                value: curMicObj?.id,
                label: curMicObj?.name,
            });
            setMicList(
                window?.['microphone']?.map((mic: any) => ({
                    value: mic.id,
                    label: mic.name,
                })),
            );

            setCurSpeaker({
                value: curSpeakerObj?.id,
                label: curSpeakerObj?.name,
            });
            setSpeakerList(
                window?.['speaker']?.map((speaker: any) => ({
                    value: speaker.id,
                    label: speaker.name,
                })),
            );

            setCurCamera({
                value: curCameraObj?.id,
                label: curCameraObj?.name,
            });
            setCameraList(
                window?.['camera']?.map((camera: any) => ({
                    value: camera.id,
                    label: camera.name,
                })),
            );
        };

        loadDefaultDevices();
    }, []);

    const setCurrentMic = async (mic: any) => {
        const micValue = mic?.value;
        const curMicObj = window?.['microphone']?.find((microphone: any) => microphone.id === micValue);
        setCurMic(mic);
        localStorage.setItem(VIDEO_DEFAULT_MIC_ID, micValue);
        try {
            await window.vidyoConnector.SelectLocalMicrophone({
                localMicrophone: curMicObj,
            });
        } catch (error) {
            console.error('Error selecting microphone: ', error);
        }
    };

    const setCurrentCamera = async (cam: any) => {
        const camValue = cam?.value;
        const curCamObj = window?.['camera']?.find((camera: any) => camera.id === camValue);
        setCurCamera(cam);
        localStorage.setItem(VIDEO_DEFAULT_CAMERA_ID, camValue);
        try {
            await window.vidyoConnector.SelectLocalCamera({
                localCamera: curCamObj,
            });
        } catch (error) {
            console.error('Error selecting camera: ', error);
        }
    };

    const setCurrentSpeaker = async (speaker: any) => {
        const speakerValue = speaker?.value;
        const curSpeakerObj = window?.['speaker']?.find((spk: any) => spk.id === speakerValue);
        setCurSpeaker(speaker);
        localStorage.setItem(VIDEO_DEFAULT_SPEAKER_ID, speakerValue);
        try {
            await window.vidyoConnector.SelectLocalSpeaker({
                localSpeaker: curSpeakerObj,
            });
        } catch (error) {
            console.error('Error selecting speaker: ', error);
        }
    };

    useEffect(() => {
        const eventListener = {
            onAdded: function (localCamera: any) {
                setCameraList((prev) => {
                    if (!prev?.find((camera) => camera.value === localCamera.id)) {
                        window?.['camera']?.push(localCamera);
                        return [
                            ...(prev || []),
                            {
                                value: localCamera.id,
                                label: localCamera.name,
                            },
                        ];
                    }

                    return prev;
                });
            },
            onRemoved: function (localCamera: any) {
                const saved = localStorage.getItem(VIDEO_DEFAULT_CAMERA_ID);

                if (saved === localCamera.id) {
                    localStorage.removeItem(VIDEO_DEFAULT_CAMERA_ID);
                }

                if (curCamera?.value === localCamera.id) {
                    const anyFirstDevice = window?.['camera']?.[0];

                    if (!anyFirstDevice) {
                        setCurCamera(null);
                        return;
                    }

                    localStorage.setItem(VIDEO_DEFAULT_CAMERA_ID, anyFirstDevice);
                    window?.vidyoConnector?.SelectLocalCamera({
                        localCamera: anyFirstDevice,
                    });
                    setCurCamera(anyFirstDevice);
                }

                setCameraList((prev) => prev?.filter((camera) => camera.value !== localCamera.id));
                window['camera'] = window?.['camera']?.filter((cam: any) => cam.id !== localCamera.id);
            },
            onSelected: function (localCamera: any) {
                setCurCamera({
                    value: localCamera.id,
                    label: localCamera.name,
                });
                localStorage.setItem(VIDEO_DEFAULT_CAMERA_ID, localCamera.id);
            },
            onStateUpdated: function (localCamera: any, state: any) {
                console.info('Camera state updated: ' + localCamera.name + ' State: ' + state);
            },
        };
        window.vidyoConnector.RegisterLocalCameraEventListener(eventListener);
        return () => {
            // window.vidyoConnector.UnregisterLocalCameraEventListener();
        };
    }, [curCamera?.value]);

    useEffect(() => {
        const eventListener = {
            onAdded: function (localMicrophone: any) {
                setMicList((prev) => {
                    if (!prev?.find((mic) => mic.value === localMicrophone.id)) {
                        window?.['microphone']?.push(localMicrophone);
                        return [
                            ...(prev || []),
                            {
                                value: localMicrophone.id,
                                label: localMicrophone.name,
                            },
                        ];
                    }

                    return prev;
                });
            },
            onRemoved: function (localMicrophone: any) {
                const saved = localStorage.getItem(VIDEO_DEFAULT_MIC_ID);

                if (saved === localMicrophone.id) {
                    localStorage.removeItem(VIDEO_DEFAULT_MIC_ID);
                }

                if (curMic?.value === localMicrophone.id) {
                    const anyFirstDevice = window?.['microphone']?.[0];

                    if (!anyFirstDevice) {
                        setCurMic(null);
                        return;
                    }

                    localStorage.setItem(VIDEO_DEFAULT_MIC_ID, anyFirstDevice);
                    window?.vidyoConnector?.SelectLocalMicrophone({
                        localMicrophone: anyFirstDevice,
                    });
                    setCurMic(anyFirstDevice);
                }

                setMicList((prev) => prev?.filter((mic) => mic.value !== localMicrophone.id));
                window['microphone'] = window?.['microphone']?.filter((mic: any) => mic.id !== localMicrophone.id);
            },
            onSelected: function (localMicrophone: any) {
                setCurMic({
                    value: localMicrophone.id,
                    label: localMicrophone.name,
                });
                localStorage.setItem(VIDEO_DEFAULT_MIC_ID, localMicrophone.id);
            },
            onStateUpdated: function (localMicrophone: any, state: any) {
                console.info('Microphone state updated: ' + localMicrophone.name + ' State: ' + state);
            },
        };
        window.vidyoConnector.RegisterLocalMicrophoneEventListener(eventListener);
        return () => {
            // window.vidyoConnector.UnregisterLocalMicrophoneEventListener();
        };
    }, [curMic?.value]);

    useEffect(() => {
        const eventListener = {
            onAdded: function (localSpeaker: any) {
                setSpeakerList((prev) => {
                    if (!prev?.find((speaker) => speaker.value === localSpeaker.id)) {
                        window?.['speaker']?.push(localSpeaker);
                        return [
                            ...(prev || []),
                            {
                                value: localSpeaker.id,
                                label: localSpeaker.name,
                            },
                        ];
                    }

                    return prev;
                });
            },
            onRemoved: function (localSpeaker: any) {
                const saved = localStorage.getItem(VIDEO_DEFAULT_SPEAKER_ID);

                if (saved === localSpeaker.id) {
                    localStorage.removeItem(VIDEO_DEFAULT_SPEAKER_ID);
                }

                if (curSpeaker?.value === localSpeaker.id) {
                    const anyFirstDevice = window?.['speaker']?.[0];

                    if (!anyFirstDevice) {
                        setCurSpeaker(null);
                        return;
                    }

                    localStorage.setItem(VIDEO_DEFAULT_SPEAKER_ID, anyFirstDevice);
                    window?.vidyoConnector?.SelectLocalSpeaker({
                        localSpeaker: anyFirstDevice,
                    });
                    setCurSpeaker(anyFirstDevice);
                }

                window['speaker'] = window?.['speaker']?.filter((spk: any) => spk.id !== localSpeaker.id);
                setSpeakerList((prev) => prev?.filter((speaker) => speaker.value !== localSpeaker.id));
            },
            onSelected: function (localSpeaker: any) {
                setCurSpeaker({
                    value: localSpeaker.id,
                    label: localSpeaker.name,
                });
                localStorage.setItem(VIDEO_DEFAULT_SPEAKER_ID, localSpeaker.id);
            },
            onStateUpdated: function (localSpeaker: any, state: any) {
                console.info('Speaker state updated: ' + localSpeaker.name + ' State: ' + state);
            },
        };
        window.vidyoConnector.RegisterLocalSpeakerEventListener(eventListener);
        return () => {
            // window.vidyoConnector.UnregisterLocalSpeakerEventListener();
        };
    }, [curSpeaker?.value]);

    return (
        <>
            <div className={styles.settingsRow}>
                <MHSelect
                    closeMenuOnSelect={true}
                    label="Microphone"
                    onChangeHandler={setCurrentMic}
                    value={curMic}
                    options={micList}
                />
                <MHButton
                    onClickHandler={async () => {
                        await vidyoConnector.SelectDefaultMicrophone();
                    }}
                >
                    Select system default microphone
                </MHButton>
            </div>
            <div className={styles.settingsRow}>
                <MHSelect
                    closeMenuOnSelect={true}
                    label="Camera"
                    onChangeHandler={setCurrentCamera}
                    value={curCamera}
                    options={cameraList}
                />
                <MHButton
                    onClickHandler={async () => {
                        await vidyoConnector.SelectDefaultCamera();
                    }}
                >
                    Select system default camera
                </MHButton>
            </div>
            <div className={styles.settingsRow}>
                <MHSelect
                    closeMenuOnSelect={true}
                    label="Speaker"
                    onChangeHandler={setCurrentSpeaker}
                    value={curSpeaker}
                    options={speakerList}
                />
                <MHButton
                    onClickHandler={async () => {
                        await vidyoConnector.SelectDefaultSpeaker();
                    }}
                >
                    Select system default speaker
                </MHButton>
            </div>
        </>
    );
};
