import {VIDEO_DEFAULT_CAMERA_ID} from '@/constants/constants';

declare global {
    interface Window {
        vidyoConnector: any;
        camera: any;
    }
}

export const registerCamera = async () => {
    window['camera'] = [];

    try {
        await window.vidyoConnector.RegisterLocalCameraEventListener({
            onAdded: function (localCamera: any) {
                window['camera'].push(localCamera);
                console.info('Camera added: ' + localCamera.name);
            },
            onRemoved: function (localCamera: any) {
                window['camera'] = window['camera'].filter(function (cam: any) {
                    return cam.id !== localCamera.id;
                });
                console.info('Camera removed: ' + localCamera.name);
            },
            onSelected: function (localCamera: any) {
                console.info('Camera selected: ' + localCamera.name);
                const savedCamera = localStorage.getItem(VIDEO_DEFAULT_CAMERA_ID);
                if (!savedCamera) {
                    localStorage.setItem(VIDEO_DEFAULT_CAMERA_ID, localCamera.id);
                }
            },
            onStateUpdated: function (localCamera: any, state: any) {
                console.info('Camera state updated: ' + localCamera.name + ' State: ' + state);
            },
        });
        console.info('LocalCameraEventListener registered');
    } catch (error) {
        console.error('RegisterLocalCameraEventListener failed', error);
    }
};
