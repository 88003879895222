import {VIDEO_DEFAULT_SPEAKER_ID} from '@/constants/constants';

declare global {
    interface Window {
        vidyoConnector: any;
        speaker: any;
    }
}

export const registerSpeaker = async () => {
    window['speaker'] = [];

    try {
        await window.vidyoConnector.RegisterLocalSpeakerEventListener({
            onAdded: function (localSpeaker: any) {
                window['speaker'].push(localSpeaker);
                console.info('Speaker added: ' + localSpeaker.name);
            },
            onRemoved: function (localSpeaker: any) {
                window['speaker'] = window['speaker'].filter(function (spk: any) {
                    return spk.id !== localSpeaker.id;
                });
                console.info('Speaker removed: ' + localSpeaker.name);
            },
            onSelected: function (localSpeaker: any) {
                console.info('Speaker selected: ' + localSpeaker.name);
                const savedSpeaker = localStorage.getItem(VIDEO_DEFAULT_SPEAKER_ID);
                if (!savedSpeaker) {
                    localStorage.setItem(VIDEO_DEFAULT_SPEAKER_ID, localSpeaker.id);
                }
            },
            onStateUpdated: function (localSpeaker: any, state: any) {
                console.info('Speaker state updated: ' + localSpeaker.name + ' State: ' + state);
            },
        });
        console.info('LocalSpeakerEventListener registered');
    } catch (error) {
        console.error('RegisterLocalSpeakerEventListener failed', error);
    }
};
