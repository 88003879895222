import {VIDEO_DEFAULT_MIC_ID} from '@/constants/constants';

declare global {
    interface Window {
        vidyoConnector: any;
        microphone: any;
    }
}

export const registerMicrophone = async () => {
    window['microphone'] = [];

    try {
        await window.vidyoConnector.RegisterLocalMicrophoneEventListener({
            onAdded: function (localMicrophone: any) {
                window['microphone'].push(localMicrophone);
                console.info('Microphone added: ' + localMicrophone.name);
            },
            onRemoved: function (localMicrophone: any) {
                window['microphone'] = window['microphone'].filter(function (mic: any) {
                    return mic.id !== localMicrophone.id;
                });
                console.info('Microphone removed: ' + localMicrophone.name);
            },
            onSelected: function (localMicrophone: any) {
                console.info('Microphone selected: ' + localMicrophone.name);
                const savedMicrophone = localStorage.getItem(VIDEO_DEFAULT_MIC_ID);
                if (!savedMicrophone) {
                    localStorage.setItem(VIDEO_DEFAULT_MIC_ID, localMicrophone.id);
                }
            },
            onStateUpdated: function (localMicrophone: any, state: any) {
                console.info('Microphone state updated: ' + localMicrophone.name + ' State: ' + state);
            },
        });
        console.info('LocalMicrophoneEventListener registered');
    } catch (error) {
        console.error('RegisterLocalMicrophoneEventListener failed', error);
    }
};
